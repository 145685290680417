@import './shared/styles/_vuetify-overrides.scss';





























.v-top-sheet {
  align-self: flex-start !important;
}

.top-sheet-transition {
  &-enter {
    transform: translateY(-100%);
  }

  &-leave-to {
    transform: translateY(-100%);
  }
}
